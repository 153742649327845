// configure dashboard table, add columns etc
import * as React from 'react';
import Info from '../components/Info';
import Path_Component from '../components/Path_Component';
import Link from "@cloudscape-design/components/link";


function createLabelFunction(columnName) {
    return ({ sorted, descending }) => {
      const sortState = sorted ? `sorted ${descending ? 'descending' : 'ascending'}` : 'not sorted';
      return `${columnName}, ${sortState}.`;
    };
}

export const columnDefinitions = [
    {
      id: "name",
      header: "Name",
      cell: item => (
        <Info
          name={item.fname}
          date={item.fdate}
          path={item.path}
          short_summ={item.short_summ}
          about_the_customer={item.about_the_customer}
          customer_opp={item.customer_opp}
          prototype_scope={item.prototype_scope}
          prototype_outcome={item.prototype_outcome}
          AI_summary={item.AI_summary}
          services={item.services}
          location={item.places}
          assets={item.assets}
        ></Info>),
      isRowHeader: true
    },
    {
      id: "date",
      header: "Date",
      cell: item => item.fdate,
      sortingField: "date",
      ariaLabel: createLabelFunction("Date"),
    },
    {
      id: "proto_arch",
      header: "Prototyping Architect",
      cell: item => item.proto_arch
    },
    {
      id: "poc",
      header: "Engagement Manager",
      cell: item => item.poc
    },
    {
      id: "usecase",
      header: "Use Case",
      cell: item => item.use_case
    },
    {
      id: "path",
      header: "Path",
      cell: item => (<Path_Component l={item.path}></Path_Component>)
    },
    {
        id: "assets",
        header: "Assets",
        cell: item => item.assets && (<Link external href={item.assets} target={"_blank"}>{item.assets.substring(8,28)}...</Link>)
    },
  ];

  export const columnDisplay= [
    { id: "name", visible: true },
    { id: "date", visible: true },
    { id: "proto_arch", visible: true },
    { id: "poc", visible: true },
    {id: "usecase", visible: true},
   { id: "path", visible: true },
   { id: "assets", visible: true }
  ];
