import { useState, useEffect } from 'react';
 
const store_all_locations = [
  "Austria", "Bahrain", "Belgium", "Czech Republic", "Denmark", "England", "Finland", "France", "Germany", "Hungary", "Ireland", "Israel", "Italy", "Jordan", "Liechtenstein", "Luxembourg", "Netherlands", "New Zealand", "Norway", "Poland", "Qatar", "Romania", "Saudi Arabia", "Scotland", "South Africa", "Spain", "Sweden", "Switzerland", "Turkey", "Ukraine", "UAE", "UK"
]


export function useAllServices() {

    const [storeAllServices, setStoreAllServices] = useState([]);
    const fetchServices = async () => {
      try {
        const response = await fetch('https://aywb24tt61.execute-api.us-east-1.amazonaws.com/prod/filter-labels?TableName=all-services-auto'); // Replace with your API endpoint
        const responseBody = await response.json();
        //console.log("ResponseBody:",responseBody)
        const serviceItems = responseBody.Items.map((item) => item.name.S).sort();
        setStoreAllServices(serviceItems);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };


useEffect(() => {    fetchServices();
  } )

  return [storeAllServices,store_all_locations];
}