// landing page for navigation in react
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import App from './js/App';
import reportWebVitals from './reportWebVitals';
import "@cloudscape-design/global-styles/index.css";
import Auth from './js/Auth';
import ErrorPage from './js/error';
import DevPage from './js/under_dev';
import Layout from './js/Layout';
import { AuthenticationDetails ,CognitoUser, CognitoAccessToken, CognitoRefreshToken, CognitoIdToken, CognitoUserSession, CognitoUserPool } from "amazon-cognito-identity-js";
import * as qs from 'qs';
import axios from "axios";
import jwt_decode from "jwt-decode";
import { Amplify } from 'aws-amplify';


const root = ReactDOM.createRoot(document.getElementById('root'));
 const midway = (event) => {
   const queryParams = new URLSearchParams(window.location.search);
   var code = queryParams.get('code')      
   var data = qs.stringify({
       'grant_type': 'authorization_code',
       'client_id': '7f18t1c14cn1b954d69a66qs6g',
       'code': code,
       'redirect_uri': 'https://protoview.prototyping.aws.dev/dashboard'
     });
     var config = {
       method: 'post',
       url: 'https://prototype-report-tool.auth.us-east-1.amazoncognito.com/oauth2/token',
       headers: {
         'Content-Type': 'application/x-www-form-urlencoded'
       },
       data : data
     };
     return(axios(config))
     .then(function (response) {
  
       var token = response.data.id_token;
       var decoded = jwt_decode(token);
     
       //console.log("Decoded Token", decoded.identities[0].userId);
       localStorage.setItem("user",decoded.identities[0].userId);
       //console.log(decoded.identities[0]);
       //console.log(decoded.identities);
     
       var AccessToken = new CognitoAccessToken({ AccessToken: response.data.access_token });
       var IdToken = new CognitoIdToken({ IdToken: response.data.id_token });
       var RefreshToken = new CognitoRefreshToken({ RefreshToken: response.data.refresh_token });
     
       const sessionData = {
         IdToken: IdToken,
         AccessToken: AccessToken,
         RefreshToken: RefreshToken
       };
     
       const userSession = new CognitoUserSession(sessionData);
       localStorage.setItem('uId', decoded.identities[0].userId);
       var poolData = {
           UserPoolId: 'us-east-1_iBZKa6U8I', // Your user pool id here
       ClientId: '7f18t1c14cn1b954d69a66qs6g', // Your client id here
       };
       
      var userPool = new CognitoUserPool(poolData);
      
       const userData = {
         Username: decoded.identities[0].userId,
         Pool: userPool
       };
     
       const cognitoUser = new CognitoUser(userData);
       cognitoUser.setSignInUserSession(userSession);
     
         cognitoUser.getSession(function (err, session) {  //You must run this to verify that session (internally)
         if (session.isValid()) {
              //Update attributes or whatever else you want to do
            localStorage.setItem("user", userData.Username)
            localStorage.setItem("authenticated", true); //naj
             //window.location.href = '/dashboard'
         } 
         });
     })
     .catch(function (error) {
        //Catch the error and perform any changes
       console.log(error); //Layout-Auth-Auth-ErrorPage-App
     });
 
 }
 midway().then(()=> {
   root.render(
      <React.StrictMode>
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout/>}></Route> 
            <Route index element={<Auth/>}></Route>
            <Route path="login" element={<Auth/>}></Route>
            <Route path="error" element={<ErrorPage/>}></Route>
            <Route path="dashboard" element={<App/>}></Route>     
        </Routes>
        </BrowserRouter>
      </React.StrictMode>
 );
   // to log results (for example: reportWebVitals(console.log))
   // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
   reportWebVitals();
 })


