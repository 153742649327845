// component shown when clicked on report name to view more details
import * as React from "react";
import Popover from "@cloudscape-design/components/popover";
import ColumnLayout from "@cloudscape-design/components/column-layout";
import SpaceBetween from "@cloudscape-design/components/space-between";
import Box from "@cloudscape-design/components/box";
import Badge from "@cloudscape-design/components/badge";
import '../css/Info.css'
import Path_Component from "../components/Path_Component"
import Link from "@cloudscape-design/components/link";

const Info = (props) => {
    const {name, date, path, services, location, AI_summary, prototype_outcome, prototype_scope, about_the_customer, customer_opp, short_summ, assets} = props;
    return (
        <Popover
            key = "Popover"
            fixedWidth
            header= {name}
            size="large"
            content={
                <ColumnLayout columns={1} variant="text-grid">
                    <SpaceBetween size="l">
                        <div>
                            <Box variant="awsui-key-label">
                            Date
                            </Box>
                            <div key="Date">{date}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">FilePath</Box>
                            <div>
                                <Path_Component key="Path" l={path}>
                                </Path_Component>
                            </div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">
                            Labels
                            </Box>
                            <div>
                                {services.map((labels)=><Badge key="Labels" color="red" className='badges'>{labels}</Badge>)}
                                {location.map((labels)=><Badge key="Labels" color="blue" className='badges'>{labels}</Badge>)}
                            </div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">
                            Quick Summary
                            </Box>
                            <div key="Quick Summary">{short_summ}</div>
                        </div>
                    <div>
                            <Box variant="awsui-key-label">
                            About The Customer
                            </Box>
                            <div key="About The Customer">{about_the_customer}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">
                            Customer Opportunity
                            </Box>
                            <div key="Customer Opportunity">{customer_opp}</div>
                        </div>
                    <div>
                            <Box variant="awsui-key-label">
                            Prototyping Scope
                            </Box>
                            <div key="Prototyping Scope">{prototype_scope}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">
                            Prototyping Outcome
                            </Box>
                            <div key="Prototyping Outcome">{prototype_outcome}</div>
                        </div>
                    <div>
                            <Box variant="awsui-key-label">
                            Detailed Summary
                            </Box>
                            <div key="Detailed Summary">{AI_summary}</div>
                        </div>
                        <div>
                            <Box variant="awsui-key-label">
                                Assets
                            </Box>
                            <div key="Assets"><Link external href={assets} target={"_blank"}>{assets}</Link></div>
                        </div>
                        </SpaceBetween>
                </ColumnLayout>
            }
        >
            {name}
        </Popover>
    );
}


export default Info;