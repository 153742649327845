import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function DevPage() {
  

  return (
    <div>
      <h1>ProtoView under development</h1>
      <p>Site is under construction. Please try after sometime.</p>
    </div>
  );
}

export default DevPage;