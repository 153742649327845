// login page
import { Alert, Button, SpaceBetween, Header,Grid } from "@cloudscape-design/components";
import { useNavigate } from "react-router-dom";
import '../css/Auth.css';
import proto from '../proto.png'
import React, { useContext,useEffect, createContext, useState } from "react";
import { AuthenticationDetails ,CognitoUser, CognitoAccessToken, CognitoRefreshToken, CognitoIdToken, CognitoUserSession, CognitoUserPool } from "amazon-cognito-identity-js";
import * as qs from 'qs';
import axios from "axios";
import jwt_decode from "jwt-decode";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  redirect,
  useLocation
} from "react-router-dom";



const Auth = () => {
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [alertAria, setAlertAria] = useState("");
    const [alertType, setAlertType] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    
    const routeChange = () =>{
        window.location.replace('https://prototype-report-tool.auth.us-east-1.amazoncognito.com/oauth2/authorize?client_id=7f18t1c14cn1b954d69a66qs6g&response_type=code&scope=email+openid&redirect_uri=https://protoview.prototyping.aws.dev/dashboard&identity_provider=FederateOIDC');
      }
    //useeffect runs when loaded, this changes title shown in the tab
    useEffect(() => {
        document.title = 'ProtoView Login';
            
          
      }, []);

    return (

        <div>
        <Alert
            statusIconAriaLabel={alertAria}
            type={alertType}
            visible={showAlert}
        >
            {alertMessage}
        </Alert>
        <div className="div">
            
        <Grid
          gridDefinition={[{ colspan: 4 }, { colspan:  4 }, { colspan:  4 }]}
        >
            <div>
            </div>
            <div>
                <SpaceBetween direction="vertical" size="s">
                <img src={proto} alt="Proto" width="75" height="75" />
                    <Header
                        variant="h1"
                        description = "Prototyping Report Tool"
                    >
                     ProtoView
                    </Header>  
                    <SpaceBetween direction="vertical" size="s">


                    <Button
                    ariaExpanded
                    variant="primary"
                    iconAlign="right"
                    onClick={routeChange}
                    >
                    Sign In
                    </Button>
                    


                    </SpaceBetween>


                    
                            {/* </Container> */}
                </SpaceBetween>
           
            </div>

            <div></div>
        </Grid>
        </div>
        </div>
        

    )
    };
  

export default Auth;