// component shown for file path  in workdocs
import * as React from "react";
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";

const Path_Component = (props) =>{
    const a = [];
    for (let i=0; i < props.l.length; i++) {
        a.push({text:props.l[i]});
    }

    return (
        <BreadcrumbGroup
          items={a}
          ariaLabel="Breadcrumbs"
        />
    )
}

export default Path_Component;