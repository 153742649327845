// component shown when dashboard is empty
import * as React from 'react';
import { Box,SpaceBetween,Button } from '@cloudscape-design/components';

const EmptyDashboard = () => {
    return (
        <Box
        margin={{ vertical: "xs" }}
        textAlign="center"
        color="inherit"
        >
            <SpaceBetween size="m">
            <b>No resources</b>
            </SpaceBetween>
        </Box>
    );
}

export default EmptyDashboard;