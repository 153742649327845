// dashboard page
import '../css/App.css';
import ErrorPage from './error';
import {
  Table,
  Alert,
  TextContent,
  ContentLayout,
  Header,
  SpaceBetween,
  Button,
  Container,
  Input,
  Grid,
  Box,
  Checkbox, 
  ExpandableSection, 
  TokenGroup, 
  Textarea,
  Autosuggest,
  FileUpload,
  FormField,
  Modal,
  Spinner,
  DatePicker
} from '@cloudscape-design/components';
import { useState, useEffect } from 'react';
import * as React from "react";
import {useAllServices} from "../data/data"
import Filter from "../components/Filter"
import { useCollection } from '@cloudscape-design/collection-hooks';
import { Navigate } from 'react-router-dom';
import {columnDefinitions, columnDisplay} from "./table-config";
import EmptyDashboard from '../components/EmptyDashboard';
import { CognitoUser, CognitoAccessToken, CognitoRefreshToken, CognitoIdToken, CognitoUserSession, CognitoUserPool } from "amazon-cognito-identity-js";
import { useNavigate } from "react-router-dom";
import '../css/Auth.css';
import * as qs from 'qs';
import axios from "axios";
import jwt_decode from "jwt-decode";
  

// filtering labels when checkbox is ticked
function filter(l, word) {
  const a = [];
  word = word.toLowerCase();
  for (let i = 0; i < l.length; i++) {
    if (l[i].toLowerCase().match(word)) {
      a.push(l[i]);
    }
  }
  return a;
}



// filtering labels when checkbox is unticked
function removeFromList(s, arr, set) {
  const newArr = new Array();
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].label != s) {
      newArr.push({label:arr[i].label})
    }
  }
  set(newArr);
}

const context={selecting:false}
function App() {
  const [all_services,all_locations] = useAllServices();
  const [all_items, setAllItems] = React.useState([]);
  const [prompt, setPrompt] = useState("");
  const [chatQuestion, setChatQuestion] = useState("");
  const [chatAnswer, setChatAnswer] = useState("");

  const [filterLabels, setFilterLabels] = React.useState([]);
  const [promptFilterLabels, setPromptFilterLabels] = React.useState([]);
  const [authenticated, setAuthenticated] = React.useState(null);
  const [loaded, setLoaded] = React.useState(false);
  const [value, setValue] = React.useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [labelsFilter, setlabelsFilter] = React.useState("");
  const [servicesFilter, setServicesFilter] = React.useState([]);
  const [locationsFilter, setLocationsFilter] = React.useState([]);
  const [triggerRerender, setTrigger] = React.useState(false);
  const navigate = useNavigate();
  
  // useeffect runs when a page is loaded up
  useEffect(() => {
    document.title = "ProtoView";
    const loggedInUser = localStorage.getItem("authenticated");
   // console.log("Logged In User:",loggedInUser);
    if (loggedInUser) {
      setAuthenticated(true);
      // fetch all items using an api
      {
      const api = "https://aywb24tt61.execute-api.us-east-1.amazonaws.com/prod/report-details"
      const fetchReports = async () => {
        const result = await fetch(api);
        result.json().then(json => {
          setAllItems(json);
          setLoaded(true);
        }

        )
      }
      try {
        fetchReports();
      } catch (error) {
        console.log(error)
      }
      }
    } else {
      setAuthenticated(false);
      navigate("/error")
    }
  }, []);

  const midway = (event) => {
    //localStorage.setItem("authenticated","true");
    const queryParams = new URLSearchParams(window.location.search);
    var code = queryParams.get('code')      
    var data = qs.stringify({
        'grant_type': 'authorization_code',
        'client_id': '7f18t1c14cn1b954d69a66qs6g',
        'code': code,
        'redirect_uri': 'https://protoview.prototyping.aws.dev/dashboard'
      });
      var config = {
        method: 'post',
        url: 'https://prototype-report-tool.auth.us-east-1.amazoncognito.com/oauth2/token',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data : data
      };
      axios(config)
      .then(function (response) {
      
        var token = response.data.id_token;
        var decoded = jwt_decode(token);
      
        //console.log("Decoded Token", decoded.identities[0].userId);
        localStorage.setItem("user",decoded.identities[0].userId);
        localStorage.setItem("authenticated","true");
        //console.log(decoded.identities[0]);
        //console.log(decoded.identities);
      
        var AccessToken = new CognitoAccessToken({ AccessToken: response.data.access_token });
        var IdToken = new CognitoIdToken({ IdToken: response.data.id_token });
        var RefreshToken = new CognitoRefreshToken({ RefreshToken: response.data.refresh_token });
      
        const sessionData = {
          IdToken: IdToken,
          AccessToken: AccessToken,
          RefreshToken: RefreshToken
        };
      
        const userSession = new CognitoUserSession(sessionData);
        localStorage.setItem('uId', decoded.identities[0].userId);
        //localStorage.setItem("authenticated","true");
      
      
        var poolData = {
          UserPoolId: 'us-east-1_iBZKa6U8I', // Your user pool id here
      ClientId: '7f18t1c14cn1b954d69a66qs6g', // Your client id here
      };
      
     var userPool = new CognitoUserPool(poolData);
     
      const userData = {
        Username: decoded.identities[0].userId,
        Pool: userPool
      };
      
        const cognitoUser = new CognitoUser(userData);
        cognitoUser.setSignInUserSession(userSession);
      
          cognitoUser.getSession(function (err, session) {  //You must run this to verify that session (internally)
          if (session.isValid()) {
               //Update attributes or whatever else you want to do
             localStorage.setItem("user", userData.Username)
             localStorage.setItem("authenticated", "true"); 
             // window.location.href = '/dashboard'
          } else {
             //TODO: What to do if session is invalid?
             navigate("/login");
             localStorage.setItem("authenticated", false);
          }
          });
      })
      .catch(function (error) {
         //Catch the error and perform any changes
        //console.log(error);
      });

}

// useEffect(() => {
//   midway();
// })

const handleKeyDown = (event,prompt) => {
 // console.log("selecting",context.selecting)
  if (event.detail.key === 'Enter' && prompt && !context.selecting ) {
    //console.log("KeyDown")
    context.selecting  = false
    handlePromptSubmit(null,prompt);
  }
}

// const handleKeyDownone = (event,prompt,setChatAnswer) => {
//   // console.log("selecting",context.selecting)
//    if (event.detail.key === 'Enter' && prompt && !context.selecting ) {
//      //console.log("KeyDown")
//      context.selecting  = false
//      handleChatSubmit(null,prompt, setChatAnswer);
//    }
//  }


const handleHeaderClick = () =>{
  window.location.replace('https://protoview.prototyping.aws.dev/dashboard');
}


// const handleChatSubmit = async (e,question,setChatAnswer) => {
//   setIsLoad(true);
//   e && e.preventDefault();
//   const requestOptions = {
//       method: 'POST',
//       headers: { 'Content-Type': 'application/json' },
//       body: JSON.stringify({ "prompt": question , "simple": false})
//   };
//   //console.log("Prompt in submit=", prompt);
//   setIsLoad(true);
//   const api = "https://aywb24tt61.execute-api.us-east-1.amazonaws.com/prod/chatbot";
//   //console.log(question)
//   const sendChat = async () => {
//     setChatAnswer("Please Wait...Generating Response...")
//     const result = await fetch(api, requestOptions);
//     //setChatAnswer("Please Wait...Generating Response...")
//     //console.log(result)
    
//     result.json().then(json=>{
//       //console.log("result json")
//       const body = JSON.parse(json.body);
//       setChatAnswer(body.response_bedrock)
//       //console.log("body",body)
//     })
//   }
//   try {
//     setIsLoad(true);
//     //console.log(isLoading);
//     await sendChat()
//   } catch (error) {
//     //console.log(error)
//   } finally {
//     //console.log()
//     setIsLoad(false);
//   }
// }
///////


//////////Parse-Prompt//////////
const handlePromptSubmit = async (e,prompt) => {
    setIsLoading(true);
    e && e.preventDefault();
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ "prompt": prompt , "simple": false})
    };
    //console.log("Prompt in submit=", prompt);
    setIsLoading(true);
    const api = "https://aywb24tt61.execute-api.us-east-1.amazonaws.com/prod/parse-prompt";
    const sendPrompt = async () => {
      const result = await fetch(api, requestOptions);
     // console.log(result)
      
      result.json().then(json=>{
        const body = JSON.parse(json.body);
       // console.log("body",body)
        const n_l = new Array()
        for (let i=0; i < body.labels.length; i++) {
         n_l.push({label: body.labels[i]})
        }
        setPromptFilterLabels(n_l);
        setAllItems(body.companies)
        
      })
    }
    try {
      setIsLoading(true);
      //console.log(isLoading);
      await sendPrompt()
    } catch (error) {
      //console.log(error)
    } finally {
      setIsLoading(false);
    }
  }
//////////Parse-Prompt-END//////////
  

  // make collection of items to be viewed in table, cannto be sorted in front end without it
  const { items, actions, filteredItemsCount, collectionProps, filterProps, paginationProps, propertyFilterProps } = useCollection(
    all_items,
    {
      propertyFiltering: {
        filteringProperties: [
          {key:'places',
            operators:[
              {operators:"=", match: (itemValue, tokenValue) => itemValue.includes(tokenValue)},
              {operators:"!=", match: (itemValue, tokenValue) => !itemValue.includes(tokenValue)},
            ]
          },
          {key:'services',
            operators:[
              {operators:"=", match: (itemValue, tokenValue) => itemValue.includes(tokenValue)},
              {operators:"!=", match: (itemValue, tokenValue) => !itemValue.includes(tokenValue)},
            ]
          },
        ]
      },
      sorting: {},
    }
  );
  

  // checks if logged in
  if (localStorage.getItem("authenticated")=="false") {
    // if nto logged in redirect to login page
    return (navigate("/login"))
  } else {
   //console.log("Prompt=",prompt)
    return (
      
      <ContentLayout
        header = {
          <Container>
            <Header
              variant="h1"
              description="View, Filter and Search for Prototyping Reports"
              actions={
                <SpaceBetween direction="horizontal" size="m"> 
                   
                  <Box variant='h4'>Welcome, {localStorage.getItem("user")}@</Box>
                  {/* <Button
                
                    onClick={() => {
                      navigate("/login");
                    }}
                    variant="primary"
                    fullWidth>
                        Logout
                    </Button> */}
                  

                </SpaceBetween>
              }
            >
              ProtoView
            </Header>
          </Container>
        }  
      >
        
        {/* filter and search side bar and table content container */}
        <Container>
          <Grid
            gridDefinition={[{ colspan: 3 }, { colspan:  9 }]}
          >
            
            {/* Search and Filter */}
            <div
            
            >
              {/* Search */}
              <Container>
              <SpaceBetween direction="vertical" size="xs">

                <Box variant="h3">
                  Search:
                </Box>
                <SpaceBetween direction="vertical" size="l">
                <div>
        <Autosuggest
      onChange={({ detail }) => setPrompt(detail.value)}
      value={prompt}
      onKeyDown={e => handleKeyDown(e,prompt)}
      onSelect={e => {context.selecting =true;handlePromptSubmit(null,e.detail.selectedOption.value)} }
      

      options={[
        { value: "Show me reports that used Claude LLM" },
        { value: "Show me reports with Airline Industry" },
        { value: "Reports that have Data Mesh architecture" },
        { value: "Reports using Bedrock" },
        { value: "Reports with Trading companies"},
        { value: "Show me BBC reports" }
      ]}
      ariaLabel="Autosuggest example with suggestions"
      placeholder="Search here"
      virtualScroll
      expandToViewport
      empty="No matches found"
    />     
     
    </div>  
    </SpaceBetween>

    

                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                            <SpaceBetween direction="vertical" size="l">
                  <Button onClick={e => handlePromptSubmit(e, prompt)}>
    
                    {isLoading ? <Spinner size="big"/> : 'Search' }
                  </Button>
                  <Button href="#" variant="inline-link" 
                    onClick={handleHeaderClick}
                  >
                    Show me all reports
                  </Button>
                
                    </SpaceBetween>

                    </div>

                  {/* <TokenGroup
                    onDismiss={({ detail: { itemIndex } }) => {
                      setPromptFilterLabels([
                        ...promptFilterLabels.slice(0, itemIndex),
                        ...promptFilterLabels.slice(itemIndex + 1)
                      ]);
                      setTrigger(!triggerRerender);
                    }}
                    items={promptFilterLabels}
                    //limit={8}
                  />  */}
                </SpaceBetween>
              </Container>
              {/* Filter */}
              {/* <SpaceBetween direction="vertical" size="s"> */}

              <Container className='filter-component'>
                  <Box variant="h3">
                    Filter by:
                  </Box>
                  <SpaceBetween direction="vertical" size="s">
                  <TokenGroup
                    onDismiss={({ detail: { itemIndex } }) => {
                      const type = filterLabels[itemIndex].tags[1];
                      const index = parseInt(filterLabels[itemIndex].tags[0])
                      if (type == "Services") {
                        const temp = servicesFilter.map(a=>a);
                        temp[index] = false;
                        setServicesFilter(temp);
                      } else {
                        const temp = locationsFilter.map(a=>a);
                        temp[index] = false;
                        setLocationsFilter(temp);

                      }
                      setFilterLabels([
                        ...filterLabels.slice(0, itemIndex),
                        ...filterLabels.slice(itemIndex + 1)
                      ]);
                      setTrigger(!triggerRerender);
                    }}
                    items={filterLabels}
                    limit={8}
                  />
                  <Button href="#" variant="inline-link" onClick={()=>{
                    // setSelectedItems([]);
                    setFilterLabels([]);
                    setLocationsFilter(all_locations.map(({s})=>false));
                    setServicesFilter(all_services.map(({s})=>false));
                  }}>
                    Clear all filters
                  </Button>

                  <Input 
                    onChange={({ detail }) => {
                      setlabelsFilter(detail.value);
                    }}
                    value={labelsFilter}
                    placeholder="Filter labels"
                  />

                  <ExpandableSection headerText="Services">
                    {labelsFilter==""?
                      all_services.map((s, index)=>(<Checkbox
                        onChange={()=>{
                          if (!servicesFilter[index] == true) {
                            if (filterLabels.length!=0) {
                              setFilterLabels([...filterLabels, {label:s, dismissLabel:"Remove "+s}])
                            } else {
                              setFilterLabels(new Array({label:s, dismissLabel:"Remove "+s}))
                            }
                          } 
                          else {
                            let g = setFilterLabels
                            removeFromList(s,filterLabels,g)
                          }
                          setTrigger(!triggerRerender);
                          const newServiceFilter = servicesFilter;
                          newServiceFilter[index] = !servicesFilter[index];
                          setServicesFilter(newServiceFilter);
                          setTrigger(!triggerRerender);
                        }}
                        checked={servicesFilter[index]}
                        >{s}</Checkbox>))
                        :
                      filter(all_services, labelsFilter).map((s, index)=>(<Checkbox 
                        onChange={()=>{
                          if (!servicesFilter[index] == true) {
                            if (filterLabels.length!=0) {
                              setFilterLabels([...filterLabels, {label:s, dismissLabel:"Remove "+s}])
                            } else {
                              setFilterLabels(new Array({label:s, dismissLabel:"Remove "+s}))
                            }
                          } 
                          else {
                            let g = setFilterLabels
                            removeFromList(s,filterLabels,g)
                          }
                          const newServiceFilter = servicesFilter;
                          newServiceFilter[index] = !servicesFilter[index];
                          setServicesFilter(newServiceFilter);
                          setTrigger(!triggerRerender);
                        }}
                        checked={servicesFilter[index]}
                        >{s}</Checkbox>))
                      }
                  </ExpandableSection>
                  <ExpandableSection headerText="Locations">
                    {labelsFilter==""?
                      all_locations.map((s, index)=>(<Checkbox
                        onChange={()=>{
                          if (!locationsFilter[index] == true) {
                            if (filterLabels.length!=0) {
                              setFilterLabels([...filterLabels, {label:s, dismissLabel:"Remove "+s}])
                            } else {
                              setFilterLabels(new Array({label:s, dismissLabel:"Remove "+s}))
                            }
                          } 
                          else {
                            let g = setFilterLabels
                            removeFromList(s,filterLabels,g)
                          }
                          const newLocationsFilter = locationsFilter;
                          newLocationsFilter[index] = !locationsFilter[index];
                          setLocationsFilter(newLocationsFilter);
                          setTrigger(!triggerRerender);
                        }}
                        checked={locationsFilter[index]}
                      >{s}</Checkbox>)):
                      filter(all_locations, labelsFilter).map((s, index)=>(<Checkbox 
                        onChange={()=>{
                          if (!locationsFilter[index] == true) {
                            if (filterLabels.length!=0) {
                              setFilterLabels([...filterLabels, {label:s, dismissLabel:"Remove "+s}])
                            } else {
                              setFilterLabels(new Array({label:s, dismissLabel:"Remove "+s}))
                            }
                          } 
                          else {
                            let g = setFilterLabels
                            removeFromList(s,filterLabels,g)
                          }
                          const newLocationsFilter = locationsFilter;
                          newLocationsFilter[index] = !locationsFilter[index];
                          setLocationsFilter(newLocationsFilter);
                          setTrigger(!triggerRerender);
                        }}
                        checked={locationsFilter[index]}
                        >{s}</Checkbox>))} 
                  </ExpandableSection>
                 
                  </SpaceBetween>

              </Container>
              {/* </SpaceBetween> */}

              <SpaceBetween direction="vertical" size="s">
              
              {/* <Container >
                
                <Box variant="h3">
                  Q&A bot:
                </Box>
                <SpaceBetween direction="vertical" size="s">
                <div>
                <Autosuggest
      onChange={({ detail }) => setChatQuestion(detail.value)}
      value={chatQuestion}
      onKeyDown={e => handleKeyDownone(e,chatQuestion, setChatAnswer)}
      // onKeyDown={(event) => {
      //                             if (event.detail.key === 'Enter') {
      //                               setChatAnswer("Please Wait...Generating Response...")
      //                               handleChatSubmit(null, chatQuestion, setChatAnswer)
      //                                                            }
      //                           }}
      onSelect={e => {context.selecting =true;handleChatSubmit(null,e.detail.selectedOption.value, setChatAnswer)} }
      

      options={[
        { value: "Which LLM did Bedrock use for the prototyping engagement with TP ICAP Trade Capture?" },
        { value: "For the Nexity [Contact Center Analysis Prototype] engagement, what was the outcome?" },
        { value: "For the BBC [Track 2 Data Governance] engagement, can you write a detailed summary on the architecture that was built including the AWS services?" }
      ]}
      ariaLabel="Autosuggest for QandA Bot"
      placeholder="Ask me questions related to a specific report"
      virtualScroll
      expandToViewport
      empty="No matches found"
    /> 
    
    
                {/* <Input
                                onKeyDown={(event) => {
                                  if (event.detail.key === 'Enter') {
                                    setChatAnswer("Please Wait...Generating Response...")
                                    handleChatSubmit(null, chatQuestion, setChatAnswer)
                                    //getAnswerFromDatabase(chatQuestion,setChatAnswer)
                                                                 }
                                }}
                                onChange={({ detail }) => setChatQuestion(detail.value)}
                                value={chatQuestion}
                                
                                type="search"
                              /> */}
    {/* </div>  
    

                            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)' }}>
                            <SpaceBetween direction="horizontal" size="xs">

                  <Button onClick={e => handleChatSubmit(e, chatQuestion, setChatAnswer)}>
    
                    {isLoad ? <Spinner size="big"/> : 'Search' }
                  </Button> */}

                 
    

                   {/* <Button href="#" variant="inline-link" 
                    onClick={handleHeaderClick}
                  >
                    Show me all reports
                  </Button>  */}
                
                     {/* </SpaceBetween>

                    </div>
                    <TextContent>
    
                    <p>{chatAnswer}</p>

                     </TextContent>
                

              </Container> */} 
              {/* </SpaceBetween> */}

              <Container className='filter-component'>
                <Button
      ariaLabel="Report a bug (opens new tab)"
      href="https://sybeslxd.chat.qbusiness.us-east-1.on.aws/"
      iconAlign="right"
      fullWidth
      iconName="external"
      target="_blank"
      variant="primary"
    >
      Chatbot Powered by Amazon Q
    </Button>

    </Container>
    </SpaceBetween>

            </div>   
            {/* Table */}
            
            <Table
      
              {...collectionProps}
              loading={!loaded}
              columnDefinitions={columnDefinitions}
              columnDisplay={columnDisplay}
              contentDensity="compact"
              items={items.filter((item)=>{
                for (let i = 0; i < filterLabels.length; i++) {
                  if (!item.places.includes(filterLabels[i].label) && (!item.services.includes(filterLabels[i].label))) {
                    return false
                  }
                }
                for (let i = 0; i < promptFilterLabels.length; i++) {
                  if (!item.places.includes(promptFilterLabels[i].label) && (!item.services.includes(promptFilterLabels[i].label))) {
                    return false
                  }
                }
                return true;
                }).sort((a,b)=>{
                  return b.sim - a.sim
                })
              }
              loadingText="ProtoView is fetching reports...almost there..."
            
              resizableColumns
              stickyHeader
              stripedRows
              wrapLines
              trackBy="name"
              variant="borderless"
              empty={<EmptyDashboard></EmptyDashboard>}
              
            />
            
          </Grid>
          
        </Container>
      
      </ContentLayout>
      
    );
  }
}

export default App;