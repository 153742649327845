import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function ErrorPage() {
  const navigate = useNavigate();
  
  useEffect(() => {
    const redirectToLogin = () => {
        setTimeout(() => {
            navigate('/login', {replace: true});  
          }, 5000); // 5 second delay
    }
    
    redirectToLogin(); 
  }, [navigate]);

  return (
    <div>
      <h1>Unauthorized Access</h1>
      <p>You do not have access to view this page. You will be redirected to the login page.</p>
    </div>
  );
}

export default ErrorPage;